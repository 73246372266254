<div class="player-controls">
  <div class="player-control back" (click)="playerService.playPreviousSongTitle()" (keypress)="playerService.playPreviousSongTitle()" tabindex="0">
    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.11783 10.7597L13.1994 19.1238C13.3583 19.2335 13.5423 19.2893 13.7282 19.2893C13.8759 19.2893 14.0237 19.254 14.1594 19.1833C14.4661 19.0225 14.6575 18.7056 14.6575 18.3599V1.63165C14.6575 1.28594 14.4661 0.96903 14.1594 0.808253C13.8536 0.646546 13.4837 0.670709 13.1994 0.866802L1.11783 9.23093C0.86691 9.40565 0.717285 9.69096 0.717285 9.99579C0.717285 10.3006 0.86691 10.5859 1.11783 10.7597Z"
        fill="white"
      />
      <path
        d="M12.2701 10.3882L24.3516 18.7524C24.5105 18.862 24.6945 18.9178 24.8804 18.9178C25.0281 18.9178 25.1759 18.8825 25.3116 18.8118C25.6183 18.6511 25.8097 18.3342 25.8097 17.9884V1.26017C25.8097 0.914453 25.6183 0.597545 25.3116 0.436768C25.0058 0.275062 24.636 0.299225 24.3516 0.495317L12.2701 8.85945C12.0191 9.03417 11.8695 9.31948 11.8695 9.6243C11.8695 9.92913 12.0191 10.2144 12.2701 10.3882Z"
        fill="white"
      />
    </svg>
  </div>
  <div class="player-control play" (click)="playerService.playCurrentSongTitle()" (keypress)="playerService.playCurrentSongTitle()" tabindex="0">
    <svg
      *ngIf="playerService.isSongPlaying()"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.4783 29.7391C59.4783 46.1636 46.1636 59.4783 29.7391 59.4783C13.3147 59.4783 0 46.1636 0 29.7391C0 13.3147 13.3147 0 29.7391 0C46.1636 0 59.4783 13.3147 59.4783 29.7391Z"
        fill="white"
      />
      <path d="M22 20H28V40H22V20Z" fill="#205B55" />
      <path d="M32 20H38V40H32V20Z" fill="#205B55" />
    </svg>
    <svg
      *ngIf="!playerService.isSongPlaying()"
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.2392 29.7391C60.2392 46.1636 46.9246 59.4783 30.5001 59.4783C14.0756 59.4783 0.760986 46.1636 0.760986 29.7391C0.760986 13.3147 14.0756 0 30.5001 0C46.9246 0 60.2392 13.3147 60.2392 29.7391Z"
        fill="white"
      />
      <path
        d="M39.9858 28.9239L27.0988 20.0025C26.9293 19.8856 26.733 19.8261 26.5348 19.8261C26.3772 19.8261 26.2195 19.8638 26.0748 19.9391C25.7477 20.1106 25.5435 20.4486 25.5435 20.8174V38.6601C25.5435 39.0289 25.7477 39.3669 26.0748 39.5384C26.401 39.7109 26.7955 39.6851 27.0988 39.4759L39.9858 30.5545C40.2534 30.3682 40.413 30.0639 40.413 29.7387C40.413 29.4136 40.2534 29.1093 39.9858 28.9239Z"
        fill="#205B55"
      />
    </svg>
  </div>
  <div class="player-control next" (click)="playerService.playNextSongTitle()" (keypress)="playerService.playNextSongTitle()" tabindex="0">
    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.8821 9.23219L12.8006 0.868059C12.6416 0.758396 12.4576 0.702637 12.2718 0.702637C12.124 0.702637 11.9762 0.737951 11.8405 0.808582C11.5339 0.96936 11.3424 1.28627 11.3424 1.63198L11.3424 18.3602C11.3424 18.706 11.5339 19.0229 11.8405 19.1836C12.1463 19.3454 12.5162 19.3212 12.8006 19.1251L24.8821 10.761C25.133 10.5863 25.2826 10.3009 25.2826 9.99612C25.2826 9.69129 25.133 9.40598 24.8821 9.23219Z"
        fill="white"
      />
      <path
        d="M13.7299 9.60319L1.64833 1.23906C1.48942 1.12939 1.3054 1.07363 1.11954 1.07363C0.971769 1.07363 0.824002 1.10895 0.688317 1.17958C0.381632 1.34036 0.190187 1.65726 0.190187 2.00298L0.190186 18.7312C0.190186 19.077 0.381631 19.3939 0.688315 19.5546C0.994071 19.7164 1.36395 19.6922 1.64833 19.4961L13.7299 11.132C13.9808 10.9572 14.1304 10.6719 14.1304 10.3671C14.1304 10.0623 13.9808 9.77698 13.7299 9.60319Z"
        fill="white"
      />
    </svg>
  </div>
</div>
