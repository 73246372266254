import { Component } from '@angular/core';
import { PlayerService } from '../../player.service';

@Component({
  selector: 'wlx-player-seeker',
  templateUrl: './player-seeker.component.html',
  styleUrls: ['./player-seeker.component.scss'],
})
export class PlayerSeekerComponent {
  constructor(public playerService: PlayerService) {}
}
