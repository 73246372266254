import { Component } from '@angular/core';
import { PlayerService } from '../../player.service';

@Component({
  selector: 'wlx-player-controls',
  templateUrl: './player-controls.component.html',
  styleUrls: ['./player-controls.component.scss'],
})
export class PlayerControlsComponent {
  constructor(public playerService: PlayerService) {}
}
