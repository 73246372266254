<div class="player-duration-information">
  <span class="player-duration head">
    {{ playerService.getSongPosition() * 1000 | date : 'mm:ss' : 'UTC' }}
  </span>
  <span class="player-duration finish">
    {{ playerService.getSongDuration() * 1000 | date : 'mm:ss' : 'UTC' }}
  </span>
</div>
<div class="player-progress-bar">
  <input
    class="player-progress-bar-range"
    type="range"
    min="0"
    step="0.1"
    [value]="playerService.getSongPosition()"
    [max]="playerService.getSongDuration()"
    (change)="playerService.updateSongPosition($event)"
  />
  <div
    class="player-progress-bar-background head"
    [style]="'--width: ' + playerService.getSongCompletionPercentage() + '%;'"
  ></div>
  <div class="player-progress-bar-background total"></div>
</div>
